<template>
  <div class="commit">
    <div class="commit__header">
      <div class="commit__header_img">
        <img :src="initData.apparatusFileId" alt="" />
      </div>
      <div class="commit__header_text">
        <div class="title">{{ initData.apparatusName }}</div>
        <div class="subtitle">{{ initData.brandName }}</div>
      </div>
    </div>

    <div class="commit__content border">
      <div class="commit__content_title"></div>
      <div
        class="commit__content"
        v-for="(item, index) in initData.param"
        :key="index"
      >
        <div class="commit__content_title">{{ item.paramKey }}</div>
        <div v-for="(citem, cindex) in item.childParam" :key="cindex">
          <div class="commit__content_tabel">
            <div class="table-l">{{ citem.paramKey }}</div>
            <div class="table-c">
              <el-rate
                v-model="citem.fakeScore"
                @change="scoreChange(citem, citem.fakeScore)"
                void-icon-class="iconfont icon-start"
                :icon-classes="[
                  'iconfont icon-start',
                  'iconfont icon-start',
                  'iconfont icon-start',
                ]"
                :colors="['#FF3355', '#FF3355', '#FF3355']"
              >
              </el-rate>
              <div class="text">
                您的评分<span class="red">{{ citem.fakeScore * 2 }}分</span>
              </div>
            </div>
            <div class="table-r">{{ citem.scoreText }}</div>
          </div>
        </div>
      </div>
      <!-- <div class="commit__content_title">
        <el-collapse v-model="activeName1" accordion>
          <el-collapse-item title="自定义评价" class="paramList_title" name="1">
            <div class="paramList_table"></div>
          </el-collapse-item>
        </el-collapse>
      </div> -->
    </div>
    <div class="commit__content border">
      <div class="commit__upload">
        <div class="commit__content_title">上传图片</div>
        <el-upload
          action="https://apparatus.admin.ysxxlm.com/upload/upimage"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :on-success="handleUploadSuccess"
        >
          <i slot="default" class="iconfont icon-addimg"></i>
          <span>添加图片</span>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </div>
    </div>
    <div class="commit__content transBorder border">
      <div class="commit__content_title">综合感受</div>
      <div class="commit__all">
        <el-input
          type="textarea"
          :rows="7"
          :placeholder="palceholderTxt"
          v-model="textarea"
        >
        </el-input>
      </div>
    </div>

    <div class="commit__btn">
      <el-button @click="commit()">{{
        $route.params.type == 1 ? "提交点评" : "提交投诉"
      }}</el-button>
    </div>
  </div>
</template>
<script>
import check from "../api/check";
import lib from "../api/lib";
import api from "../api/api";
export default {
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      activeName1: "",
      initData: [],
      textarea: "",
      palceholderTxt: "",
      fileList: [],
    };
  },
  created() {
    this.init();
    this.apparatusCommentInfo();
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    init() {
      this.palceholderTxt =
        this.$route.params.type == 1 ? "发表您的点评。" : "阐述您投诉的理由。";
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      let data = {};
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleUploadSuccess(response, file,fileList) {
      let data = {
        file_id:response.data.file_id,
        file_path:response.data.url
      }
      this.fileList.push(data);
    },
    apparatusCommentInfo() {
      let data = {
        apparatusId: this.$route.params.id,
      };
      api.apparatusCommentInfo(data).then((res) => {
        res.data.data.param.forEach((item) => {
          item.childParam.forEach((i) => {
            i.fakeScore = 0;
            i.score = 0;
            i.scoreText = "";
          });
        });
        this.initData = res.data.data;
      });
    },
    commit() {
      
      if (!this.token) {
        this.$message({ message: "请先登录", type: "warning" });
        return;
      }
      if (this.$route.params.type == 1) {
        this.commentCommit();
      } else {
        this.aomplaintCommit();
      }
      
      this.$message({ message: "提交成功,即将返回", type: "success" });
      setTimeout(() => {
        this.$router.go(-1);  
      }, 3000);
      
    },
    commentCommit() {
      this.score();
      
      let data = {
        apparatusId: this.$route.params.id,
        detail: this.textarea,
        commentName: this.initData.apparatusName,
        files: this.fileList,
        params: this.initData.param,
      };

      check.addComment(data).then((res) => {});
    },
    aomplaintCommit() {
      this.score();
      let data = {
        apparatusId: this.$route.params.id,
        explain: this.textarea,
        explainTitle: this.initData.apparatusName,
        files: this.fileList,
        params: this.initData.param,
      };
      check.addComplain(data).then((res) => {});
    },
    scoreChange(data, score) {
      let realScore = score * 2;
      for (const i in data.mapping) {
        if (
          realScore >= data.mapping[i].rangeStart &&
          realScore <= data.mapping[i].rangeEnd
        ) {
          data.scoreText = data.mapping[i].mappingName;
        }
      }
      this.initData.param.splice(this.initData.param.length);
    },
    score() {
      this.initData.param.forEach((item) => {
        item.childParam.forEach((citem) => {
          citem.score = citem.fakeScore * 2;
        });
      });
    },
  },
};
</script>
<style lang="scss">
.commit__content_title {
  .el-collapse {
    border-top: 0;
    border-bottom: 0;
  }
}
.commit__content {
  .el-collapse-item__header {
    font-size: 19px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #999999;
    border-bottom: transparent;
  }
}
.commit__upload {
  .el-upload--picture-card {
    width: 80px;
    height: 80px;
    position: relative;
    background: #f4f5f9;
    &:hover {
      border-color: #cecece;
    }
    i {
      // font-size: 22px;
      line-height: 1;
      position: absolute;
      left: 50%;
      top: 19px;
      transform: translateX(-50%);
    }
    span {
      display: inline-block;
      width: 53px;
      line-height: 1;
      position: absolute;
      left: 50%;
      top: 49px;
      transform: translateX(-50%);
      font-size: 13px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #a3a3a3;
    }
  }
  .el-upload-list__item {
    width: 80px;
    height: 80px;
  }
  .el-icon-plus {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.commit__content_tabel {
  .table-c {
    .el-rate {
      height: auto;
      .el-rate__item {
        i {
          font-size: 27px;
        }
      }
    }
  }
}
.commit__all {
  .el-textarea__inner {
    padding: 20px 43px;
    background: #f4f5f9;
    font-size: 19px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #b9b9b9;
  }
}
.commit__btn {
  .el-button {
    width: 133px;
    height: 53px;
    border-radius: 7px 7px 7px 7px;
    opacity: 1;
    border: 1px solid #0394ff;
    font-size: 19px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #0394ff;
  }
}
</style>
<style lang="scss" scoped>
.commit {
  background: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 7px;
  // padding: 27px;
  .commit__header {
    padding: 27px;
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    .commit__header_img {
      width: 213px;
      img {
        width: 100%;
        border-radius: 7px;
      }
    }
    .commit__header_text {
      margin-left: 27px;
      .title {
        font-size: 21px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 25px;
      }
      .subtitle {
        font-size: 19px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #7d7d7d;
        line-height: 22px;
      }
    }
  }
  .commit__content {
    &.border {
      border-bottom: 1px solid #e9e9e9;
      padding: 0 27px 27px 27px;
    }
    &.transBorder {
      border-bottom: 0;
    }
  }
  .commit__content_title {
    font-size: 21px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-top: 25px;
    margin-bottom: 13px;
  }
  .commit__content {
    .commit__content_tabel {
      display: grid;
      grid-template-columns: 151px auto 151px;
      grid-template-rows: 53px;
      align-items: center;
      background: #fff;
      text-align: center;
      line-height: 53px;
      border: 1px solid #e9e9e9;
      margin-top: -1px;
      div {
        // height: 100%;
      }
      .table-l {
        background: #fbfbfb;
        border-right: 1px solid #e9e9e9;

        margin-top: -1px;
        margin-right: -1px;
      }
      .table-c {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        margin-top: -1px;
        margin-right: -1px;
        .text {
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-left: 13px;
          .red {
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ff3355;
            margin-left: 13px;
          }
        }
      }
      .table-r {
        background: #fbfbfb;
        border-left: 1px solid #e9e9e9;
        border-right: 1px solid #e9e9e9;
        margin-top: -1px;
        margin-right: -1px;
        height: 100%;
      }
    }
  }
  .commit__all {
    margin: 0px 0;
  }
  .commit__btn {
    padding: 0 27px;
    text-align: right;
    margin: 25px 0;
  }
}
</style>